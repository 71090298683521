import { AppViewContent } from 'models/app-view.model';

interface TandCS {
  sections: {
    title: string;
    paragraphs: string[];
  }[];
}

export const termsAndConditions: AppViewContent = {
  sections: [
    {
      title: '1. Definitions',
      paragraphs: [
        "a. 'Platform' refers to the Online Farm Produce Marketplace website or mobile application.",
        "b. 'User' refers to any individual or entity accessing or using the platform for buying or selling farm produce.",
        "c. 'Seller' refers to users who offer their farm produce for sale on the platform.",
        "d. 'Buyer' refers to users who purchase farm produce from sellers on the platform.",
      ],
    },
    {
      title: '2. User Eligibility',
      paragraphs: [
        'a. Users must be at least 18 years old and capable of entering into legally binding agreements.',
        'b. Users must provide accurate and up-to-date information during registration.',
      ],
    },
    {
      title: '3. Account Creation',
      paragraphs: [
        'a. Users are required to create an account to access certain features of the platform.',
        'b. Users are responsible for maintaining the confidentiality of their account information.',
        'c. Users are solely responsible for any activities that occur under their account.',
      ],
    },
    {
      title: '4. Farm Produce Listings',
      paragraphs: [
        'a. Sellers are responsible for providing accurate and detailed information about their farm produce listings.',
        'b. Listings should not include false, misleading, or deceptive information.',
        'c. The platform reserves the right to moderate and remove any listings that violate these Terms and Conditions.',
      ],
    },
    {
      title: '5. Purchasing Farm Produce',
      paragraphs: [
        'a. Buyers agree to pay the listed price for the farm produce, along with any applicable taxes and shipping fees.',
        'b. All sales are final unless the received produce is materially different from the listing description.',
      ],
    },
    {
      title: '6. Payments and Transactions',
      paragraphs: [
        'a. The platform may provide secure payment processing services for transactions.',
        "b. Users agree to comply with the platform's payment policies.",
      ],
    },
    {
      title: '7. Prohibited Activities',
      paragraphs: [
        'a. Users shall not engage in any illegal, fraudulent, or harmful activities on the platform.',
        "b. Users shall not interfere with the platform's operations or attempt to gain unauthorized access to its systems.",
      ],
    },
    {
      title: '8. Intellectual Property',
      paragraphs: [
        'a. Users retain ownership of their content (e.g., product listings), but grant the platform a license to use and display the content for platform-related purposes.',
        'b. Users shall not use any content from the platform without obtaining appropriate permissions.',
      ],
    },
    {
      title: '9. Privacy',
      paragraphs: [
        'a. The platform collects and uses personal information in accordance with its Privacy Policy.',
        'b. Users should review the Privacy Policy to understand how their data is collected, used, and protected.',
      ],
    },
    {
      title: '10. Limitation of Liability',
      paragraphs: [
        'a. The platform shall not be liable for any direct, indirect, incidental, or consequential damages arising from the use of the platform.',
        'b. Users use the platform at their own risk.',
      ],
    },
    {
      title: '11. Modification of Terms',
      paragraphs: [
        'a. The platform may update or modify these Terms and Conditions at any time.',
        'b. Users will be notified of any significant changes to the Terms and Conditions.',
      ],
    },
    {
      title: '12. Termination',
      paragraphs: [
        "a. The platform reserves the right to terminate or suspend users' accounts for violations of these Terms and Conditions.",
      ],
    },
    {
      title: '13. Governing Law',
      paragraphs: [
        'a. These Terms and Conditions shall be governed by and interpreted in accordance with the laws of [Your Country/State].',
      ],
    },
  ],
};
// Add other sections here...
