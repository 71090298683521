import React from 'react';

import Logo from '../../assets/demmi-logo.svg';

interface HomeProps {
  isMounted: boolean;
}

const Home: React.FC<HomeProps> = ({ isMounted }) => {
  const CSSBlock = 'home';
  const mountedStyle = { animation: 'inAnimation 200ms ease-in-out' };
  const unmountedStyle = { animation: 'outAnimation 200ms ease-in-out' };

  return (
    <div
      className={`${CSSBlock}`}
      style={isMounted ? mountedStyle : unmountedStyle}>
      <div className={`${CSSBlock}__logo`}>
        <img src={Logo} />
      </div>
      <div className={`${CSSBlock}__subtitle`}>
        <span>The</span> natural marketplace.
      </div>
    </div>
  );
};

export default Home;
