import { AppViewContentItem } from 'models/app-view.model';
import React, { useEffect, useRef, useState } from 'react';

interface Props {
  paragraphs: string[];
  active: boolean;
  i: number;
  onClick: () => void;
}

const AppViewParagraph: React.FC<Props> = ({
  paragraphs,
  active,
  i,
  onClick,
}) => {
  const CSSBlock = 'app-view';
  const questionRef = useRef<HTMLDivElement>(null);
  const answerRef = useRef<HTMLDivElement>(null);
  // const [openBlock, setOpenBlock] = useState(-1);
  const [minHeight, setMinHeight] = useState(20);
  const [maxHeight, setMaxHeight] = useState(200);

  useEffect(() => {
    if (questionRef?.current && answerRef?.current) {
      setMinHeight(questionRef.current.getBoundingClientRect().height / 10);
      setMaxHeight(
        questionRef.current.getBoundingClientRect().height / 10 +
          1.5 +
          answerRef.current.getBoundingClientRect().height / 10 +
          2 // Buffer in case of overflow line
      );
    }
  }, [questionRef, answerRef]);

  return (
    <>
      {paragraphs.map((paragraph, i) => (
        <div className={`${CSSBlock}__section-paragraph`} key={i}>
          {paragraph}
        </div>
      ))}
    </>
  );
};

export default AppViewParagraph;
