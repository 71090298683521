import React, { useEffect, useState } from 'react';

import { CSSVARS, setCSSVar } from '@helpers/themeHelper';

const fps = 60;
const duration = 2;
const easeInOutSine = (x: number) => -(Math.cos(Math.PI * x) - 1) / 2;

interface AppThemeProps {
  isAnimated: boolean;
}

const AppTheme: React.FC<AppThemeProps> = ({ isAnimated }) => {
  const [interval, setInterval] = useState<number>(0);

  let hue = Math.round(Math.random() * 360);
  let pos = 0;

  const hueRotate = () => {
    hue++;
    pos += 1 / fps / duration;
    setCSSVar(CSSVARS.THEME_BG_POS, (0 + easeInOutSine(pos) * 100).toString());
  };

  useEffect(() => {
    isAnimated
      ? setInterval(window.setInterval(hueRotate, 1000 / fps))
      : clearInterval(interval);
  }, [isAnimated]);

  return <></>;
};

export default AppTheme;
