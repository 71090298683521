import { AppViewContentItem } from 'models/app-view.model';
import React, { useEffect, useRef, useState } from 'react';

interface Props {
  item: AppViewContentItem;
  active: boolean;
  i: number;
  onClick: () => void;
}

const AppViewItem: React.FC<Props> = ({ item, active, i, onClick }) => {
  const CSSBlock = 'app-view';
  const questionRef = useRef<HTMLDivElement>(null);
  const answerRef = useRef<HTMLDivElement>(null);
  // const [openBlock, setOpenBlock] = useState(-1);
  const [minHeight, setMinHeight] = useState(20);
  const [maxHeight, setMaxHeight] = useState(200);

  useEffect(() => {
    if (questionRef?.current && answerRef?.current) {
      setMinHeight(questionRef.current.getBoundingClientRect().height / 10);
      setMaxHeight(
        questionRef.current.getBoundingClientRect().height / 10 +
          1.5 +
          answerRef.current.getBoundingClientRect().height / 10 +
          2 // Buffer in case of overflow line
      );
    }
  }, [questionRef, answerRef]);

  return (
    <div
      className={`${CSSBlock}__question ${
        active ? `${CSSBlock}__question--visible` : ''
      }`}
      key={i}
      onClick={onClick}
      style={{ maxHeight: active ? `${maxHeight}rem` : `${minHeight}rem` }}>
      <div className={`${CSSBlock}__question-title`} ref={questionRef}>
        {item.title}
      </div>

      <div className={`${CSSBlock}__question-description`} ref={answerRef}>
        {item.body}
      </div>
    </div>
  );
};

export default AppViewItem;
