import React, { HTMLInputTypeAttribute } from 'react';

interface DemmiInputProps {
  placeholder?: string;
  type: HTMLInputTypeAttribute;
  value?: string;
  name?: string;
  onChange: (val: string) => void;
}

const DemmiInput: React.FC<DemmiInputProps> = ({
  placeholder,
  type,
  value,
  name,
  onChange,
}) => {
  const CSSBlock = 'demmi-ui-input';

  return (
    <input
      className={CSSBlock}
      type={type}
      placeholder={placeholder}
      value={value}
      name={name}
      onChange={e => onChange(e.target.value)}
    />
  );
};

export default DemmiInput;
