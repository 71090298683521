import React from 'react';

import AppView from '@pages/appView/AppView';

import { faqs } from './help';

const AppHelp: React.FC = () => {
  return (
    <AppView
      content={faqs}
      pageTitle={["You've got questions.", "We've got answers."]}
    />
  );
};

export default AppHelp;
