import React, { useRef, useState } from 'react';

import DemmiCard from '@demmi-ui/Card/Card';
import DemmiCardArea from '@demmi-ui/Card/CardArea';
import DemmiCardTitleArea from '@demmi-ui/Card/CardTitleArea';
import DemmiIconButton from '@demmi-ui/IconButton';
import DemmiInput from '@demmi-ui/Input';
import DemmiModalCore, { DemmiModalCoreRef } from '@demmi-ui/ModalCore';
import DemmiTextArea from '@demmi-ui/TextArea';
import emailjs, { send } from '@emailjs/browser';

const CSSBlock = 'register-interest';

interface Props {
  open: boolean;
  onClose: () => void;
  onFormComplete: () => void;
}

const OverlayRequestFarmer: React.FC<Props> = ({
  open,
  onClose,
  onFormComplete,
}) => {
  const formRef = useRef<HTMLFormElement>(null);
  const modalRef = useRef<DemmiModalCoreRef>(null);

  const [name, setName] = useState('');
  const [farm, setFarm] = useState('');
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [isSending, setIsSending] = useState(false);

  const sendMessage = () => {
    setIsSending(true);
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID || '',
        process.env.REACT_APP_TEMPLATE_ID || '',
        formRef.current!,
        process.env.REACT_APP_PUBLIC_KEY || ''
      )
      .then(() => {
        if (modalRef.current) {
          modalRef.current.gracefulClose(() => {
            onClose();
            onFormComplete();
          });
        } else {
          onClose();
          onFormComplete();
        }
        setTimeout(() => {
          setIsSending(false);
          setName('');
          setFarm('');
          setEmail('');
          setMessage('');
        }, 1000);
      });
  };

  return (
    <DemmiModalCore ref={modalRef} isOpen={open} onExited={() => onClose()}>
      <DemmiCard className={CSSBlock}>
        <DemmiCardTitleArea
          titleText={<>Farmer Enquiry</>}></DemmiCardTitleArea>
        <DemmiCardArea isDark>
          <form ref={formRef}>
            <div style={{ padding: '2rem 2rem 1rem' }}>
              To hear more about Demmi, to find out how we can help your farm
              grow, or to simply register your interest, please fill out the
              form below and we'll be in touch as soon as possible.
            </div>
            <div
              style={{
                padding: '1rem',
                display: 'flex',
                flexDirection: 'column',
                gap: '1rem',
              }}>
              <DemmiInput
                placeholder="Your name"
                type="text"
                name="user_name"
                value={name}
                onChange={e => setName(e)}
              />
              <DemmiInput
                placeholder="What's your farm called?"
                type="text"
                name="farm_name"
                value={farm}
                onChange={e => setFarm(e)}
              />
              <DemmiTextArea
                placeholder="What would you like to discuss?"
                name="message"
                value={message}
                onChange={e => setMessage(e)}
              />
            </div>
            <div
              style={{
                padding: '0 1rem 1rem',
                display: 'flex',
                gap: '1rem',
                marginBottom: '1rem',
              }}>
              <DemmiInput
                placeholder="Email address"
                type="email"
                name="user_email"
                value={email}
                onChange={e => setEmail(e)}
              />
              {isSending === true && (
                <DemmiIconButton
                  faIcon={'fa-solid fa-circle-notch fa-spin'}
                  disabled={true}
                  onClick={e => e.preventDefault()}
                />
              )}
              {isSending === false && (
                <DemmiIconButton
                  faIcon={'fa-paper-plane'}
                  disabled={
                    name.length === 0 ||
                    message.length === 0 ||
                    email.length === 0
                  }
                  onClick={e => {
                    e.preventDefault();
                    sendMessage();
                  }}
                />
              )}
            </div>
          </form>
        </DemmiCardArea>
      </DemmiCard>
    </DemmiModalCore>
  );
};

export default OverlayRequestFarmer;
