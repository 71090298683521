import React, { HTMLProps, PropsWithChildren } from 'react';

import DemmiCardArea from './CardArea';

interface DemmiCardTitleAreaProps
  extends PropsWithChildren,
    HTMLProps<HTMLDivElement> {
  titleText: string | JSX.Element;
}

const DemmiCardTitleArea: React.FC<DemmiCardTitleAreaProps> = ({
  children,
  titleText,
  ...props
}) => {
  const CSSBlock = 'demmi-ui-card';

  return (
    <DemmiCardArea
      {...props}
      className={`${CSSBlock}__area-title ${
        props.className ? props.className : ``
      }`}>
      <div className={`${CSSBlock}__title`}>{titleText}</div>
      {children}
    </DemmiCardArea>
  );
};

export default DemmiCardTitleArea;
