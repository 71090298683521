import React, { useEffect, useState } from 'react';

import AppView from '@pages/appView/AppView';

import Logo from '../../assets/demmi-logo.svg';
import { termsAndConditions } from './termsAndConditions';

const AppTC: React.FC = () => {
  const CSSBlock = 'app-view';
  return (
    <AppView content={termsAndConditions} pageTitle={['Terms & Conditions']} />
  );
};

export default AppTC;
