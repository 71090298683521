import { createRoot } from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import 'remixicon/fonts/remixicon.css';

import '@assets/fa-all.min.js';
import AppHelp from '@pages/apphelp/AppHelp';
import AppTC from '@pages/apptc/AppTC';
import WebsiteHome from '@pages/home/WebsiteHome';
import '@styles/index.scss';

const router = createBrowserRouter([
  {
    path: '/',
    element: <WebsiteHome />,
  },
  {
    path: '/app/help',
    element: <AppHelp />,
  },
  {
    path: '/app/t&cs',
    element: <AppTC />,
  },
]);

const root = createRoot(document.getElementById('pd')!);
root.render(<RouterProvider router={router} />);
