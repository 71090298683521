import React, { CSSProperties } from 'react';

import { faStyles } from './IconButton';

export interface DemmiButtonProps {
  text: string;
  faIcon?: string;
  disabled?: boolean;
  outlined?: boolean;
  onClick: () => void;
  style?: CSSProperties;
}

const DemmiButton: React.FC<DemmiButtonProps> = ({
  text,
  faIcon,
  disabled,
  outlined,
  onClick,
  style,
}) => {
  const CSSBlock = 'demmi-ui-button';

  return (
    <button
      className={`${CSSBlock} ${outlined ? `${CSSBlock}--outlined` : ``}`}
      disabled={disabled}
      style={style}
      onClick={onClick}>
      {faIcon && (
        <span className={`${CSSBlock}__icon`}>
          <i
            className={
              faStyles.find(item => faIcon.includes(item))
                ? faIcon
                : `fa-solid ${faIcon}`
            }></i>
        </span>
      )}
      <span className={`${CSSBlock}__text`}>{text}</span>
    </button>
  );
};

export default DemmiButton;
