import React, { useState } from 'react';

import { useDelayUnmount } from '@core/hooks/useDelayUnmount';
import RegisterInterest from '@pages/home/registerInterest/RegisterInterest';

import AppTheme from '../../core/AppTheme';
import Home from './Home';
import OverlayRequestType from './overlayRequestType/OverlayRequestType';

const WebsiteHome: React.FC = () => {
  // Home
  const [isHomeMounted, setIsHomeMounted] = useState(false);
  const shouldRenderHome = useDelayUnmount(isHomeMounted, 200);

  return (
    <>
      <AppTheme isAnimated={true} />
      <Home isMounted={isHomeMounted} />
      {/* <RegisterInterest /> */}
      <OverlayRequestType />
    </>
  );
};

export default WebsiteHome;
