import { AppViewContent } from 'models/app-view.model';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import Logo from '../../assets/demmi-logo.svg';
import AppViewSection from './AppViewSection';

interface Props {
  content: AppViewContent;
  pageTitle: string[];
}

const AppView: React.FC<Props> = ({ content, pageTitle }) => {
  const CSSBlock = 'app-view';
  const [openBlock, setOpenBlock] = useState('0;0');
  const [searchParams] = useSearchParams();

  return (
    <div className={`${CSSBlock} ${searchParams.get('dark') ? 'dark' : ''}`}>
      <div className={`${CSSBlock}__scrollview`}>
        <div className={`${CSSBlock}__logo`}>
          <img src={Logo} />
        </div>

        <div className={`${CSSBlock}__title`}>
          {pageTitle.map(title => (
            <span>{title}</span>
          ))}
        </div>

        <div className={`${CSSBlock}__sections`}>
          {content.sections.map((section, i) => (
            <AppViewSection
              section={section}
              openBlock={openBlock}
              onClick={j =>
                openBlock === `${i};${j}`
                  ? setOpenBlock('-1')
                  : setOpenBlock(`${i};${j}`)
              }
              i={i}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default AppView;
