import React from 'react';

export enum IconButtonSize {
  SMALL = 'small',
  MINI = 'mini',
}

interface DemmiIconButtonProps {
  faIcon: string;
  className?: string;
  size?: IconButtonSize;
  onClick?: (e: React.MouseEvent) => void;
  tabIndex?: number;
  disabled?: boolean;
}

const CSSBlock = 'demmi-ui-icon-button';
export const faStyles = [
  'fa-solid',
  'fa-regular',
  'fa-light',
  'fa-thin',
  'fa-duotone',
];
const DemmiIconButton = React.forwardRef<
  HTMLButtonElement,
  DemmiIconButtonProps
>(({ faIcon, className, onClick, size, tabIndex, disabled }, ref) => {
  return (
    <button
      className={`${CSSBlock} ${size ? `${CSSBlock}--${size}` : ''} ${
        className || ''
      }`}
      onClick={onClick}
      ref={ref}
      disabled={disabled}
      {...(tabIndex ? { tabIndex: tabIndex } : {})}>
      <i
        className={
          faStyles.find(item => faIcon.includes(item))
            ? faIcon
            : `fa-solid ${faIcon}`
        }></i>
    </button>
  );
});

export default DemmiIconButton;
