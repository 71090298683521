import { AppViewContent } from 'models/app-view.model';

export const faqs: AppViewContent = {
  sections: [
    {
      title: 'How it works',
      icon: 'fa-question',
      items: [
        {
          title:
            'How do I contact the seller or customer support for assistance?',
          body: "You can easily contact the seller through the app's messaging system. For any general inquiries or assistance, our customer support team is available via email or phone [provide contact details].",
        },
        {
          title:
            'Are there any promotions or discounts available for frequent buyers?',
          body: "Yes, we offer special promotions, discounts, and loyalty programs for our frequent buyers. Keep an eye on the app's notifications and emails for updates.",
        },
        {
          title:
            'Is there a rating and review system for sellers and their products?',
          body: 'Yes, we have a rating and review system where buyers can rate sellers and leave feedback based on their purchase experience. This helps maintain a trustworthy community on our platform.',
        },
        {
          title:
            'Are there any membership or subscription fees associated with using the app?',
          body: 'No, our app is free to use for both buyers and sellers. There are no membership or subscription fees required.',
        },
        {
          title:
            'Can I share my favorite farm produce listings with friends or on social media?',
          body: "Yes, you can easily share your favorite listings with friends through various social media platforms or messaging apps using the 'Share' option provided.",
        },
      ],
    },
    {
      title: 'The Food',
      icon: 'fa-salad',
      items: [
        {
          title:
            'How does the app ensure the quality and freshness of the farm produce?',
          body: 'We encourage sellers to provide accurate product descriptions and images. Additionally, our platform allows buyers to leave reviews and ratings based on their experiences, ensuring transparency and accountability.',
        },
      ],
    },
    {
      title: 'The App',
      icon: 'fa-mobile',
      items: [
        {
          title: 'What is the purpose of this mobile app?',
          body: 'The purpose of our mobile app is to connect farmers and consumers, providing a convenient platform for buying and selling fresh farm produce directly.',
        },
        {
          title: 'How can I download and install the mobile app on my device?',
          body: "You can download and install our mobile app from the respective app stores for iOS and Android devices. Simply search for '[App Name]' and click on the 'Install' or 'Get' button.",
        },
        {
          title: 'How do I create an account on the app?',
          body: "To create an account, tap on the 'Sign Up' or 'Register' button on the app's homepage. Follow the instructions to provide your details and set up your account.",
        },
        {
          title: 'Can I buy and sell farm produce on this app?',
          body: 'Yes, our app allows you to both buy and sell farm produce. Consumers can browse and purchase products, while farmers can list their produce for sale.',
        },
        {
          title: 'How do I search for specific farm produce on the app?',
          body: "You can use the search bar at the top of the app's interface. Simply type in the name of the produce or related keywords, and relevant listings will appear.",
        },
        {
          title:
            'Is it possible to filter search results based on location or price?',
          body: 'Yes, you can use filters to narrow down search results by location, price range, and other criteria to find the desired farm produce.',
        },
        {
          title:
            'How do I report suspicious or inappropriate listings on the app?',
          body: "If you come across any suspicious or inappropriate listings, please report them immediately using the 'Report' option available on the product page or contact our support team.",
        },
        {
          title:
            'What are the supported devices and operating systems for the mobile app?',
          body: 'Our app is supported on iOS devices (iPhone, iPad) running iOS [min version] and Android devices running Android [min version] and higher.',
        },
      ],
    },
    {
      title: 'Orders',
      icon: 'fa-basket-shopping',
      items: [
        {
          title: 'Can I track the status of my order?',
          body: 'Absolutely! Once your order is confirmed, you will receive updates and a tracking link to monitor the status of your delivery.',
        },
        {
          title:
            'Is there a return or refund policy in case I am dissatisfied with the produce I receive?',
          body: "Yes, we have a fair return and refund policy. If you are not satisfied with the received produce, you can request a refund within [X] days of the order's collection. Please refer to our 'Return and Refund Policy' for detailed instructions.",
        },
      ],
    },
    {
      title: 'Payments',
      icon: 'fa-money-bill',
      items: [
        {
          title: 'What payment methods are accepted on the app?',
          body: 'We accept various payment methods, including credit/debit cards, mobile wallets, and other secure payment gateways to ensure a smooth transaction process.',
        },
      ],
    },
    {
      title: 'Farmers',
      icon: 'fa-farm',
      items: [
        {
          title:
            'How can I sign up as a seller and list my farm produce on the platform?',
          body: "To become a seller, go to the app's settings, select 'Become a Seller,' and follow the on-screen instructions to set up your seller account.",
        },
        {
          title:
            'What guidelines do I need to follow when creating product listings?',
          body: "We have clear guidelines for creating product listings to ensure accurate and comprehensive information about your farm produce. Please review our 'Seller Guidelines' for detailed instructions.",
        },
        {
          title:
            'Can I promote my farm produce listings to reach a wider audience?',
          body: 'Yes, we offer promotional features to help sellers reach a broader audience and increase visibility for their farm produce listings.',
        },
        {
          title: 'Can I manage multiple farm produce listings from my account?',
          body: 'Yes, as a seller, you can manage and update multiple farm produce listings conveniently from your account dashboard.',
        },
        {
          title: 'How do I track my sales and earnings as a seller?',
          body: 'You can track your sales and earnings through the seller dashboard, which provides detailed insights into your transactions and revenue.',
        },
      ],
    },
    {
      title: 'Your Account',
      icon: 'fa-user',
      items: [
        {
          title: 'Is my personal and payment information secure on the app?',
          body: 'Absolutely! We prioritize the security of your data. Our app uses advanced encryption and security measures to safeguard your personal and payment information.',
        },
        {
          title: 'Can I change my account information or password?',
          body: "Yes, you can update your account information and change your password from the app's settings.",
        },
        {
          title: 'How do I reset my password if I forget it?',
          body: "If you forget your password, click on the 'Forgot Password' link on the login page. Follow the instructions to reset your password via email or phone verification.",
        },
      ],
    },
    {
      title: 'About Demmi',
      icon: 'fa-question',
      items: [
        {
          title:
            'Are there any community forums or discussion boards for users to interact?',
          body: 'Yes, we have a community forum where users can interact, exchange ideas, and share knowledge related to farm produce and agriculture.',
        },
        {
          title:
            'Are there any seller verification or review processes to maintain quality standards?',
          body: 'Yes, we have a verification process to ensure the authenticity of seller accounts. Additionally, we encourage buyer reviews and ratings to maintain high-quality standards.',
        },
        {
          title:
            'How frequently is the app updated with new features and improvements?',
          body: 'We regularly update the app to introduce new features, improvements, and bug fixes. Make sure to keep your app updated to access the latest enhancements.',
        },
        {
          title:
            'Is there a customer support team available to assist with any issues or queries?',
          body: 'Yes, our dedicated customer support team is available [provide support hours] to assist you with any issues or queries you may have. You can reach them through [provide contact details].',
        },
      ],
    },
  ],
};
