import { AppViewContentSection } from 'models/app-view.model';
import React from 'react';

import AppViewItem from './AppViewItem';
import AppViewParagraph from './AppViewParagraph';

interface Props {
  section: AppViewContentSection;
  openBlock: string;
  i: number;
  onClick: (j: number) => void;
}

const AppViewSection: React.FC<Props> = ({
  section,
  openBlock,
  i,
  onClick,
}) => {
  const CSSBlock = 'app-view';

  return (
    <div className={`${CSSBlock}__section`}>
      <div className={`${CSSBlock}__section-title`}>
        {section.icon && (
          <div className={`${CSSBlock}__section-icon`}>
            <i className={`fa-light ${section.icon}`}></i>
          </div>
        )}
        {section.title}
      </div>
      {section.paragraphs && (
        <AppViewParagraph
          paragraphs={section.paragraphs}
          active={`${i}` === openBlock}
          onClick={() => onClick(0)}
          i={i}
        />
      )}
      {section.items &&
        section.items.map((item, j) => (
          <AppViewItem
            item={item}
            active={`${i};${j}` === openBlock}
            onClick={() => onClick(j)}
            i={i}
          />
        ))}
    </div>
  );
};

export default AppViewSection;
