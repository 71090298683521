import React, { useState } from 'react';

import DemmiButton from '@demmi-ui/Button';

import OverlayComplete from '../overlayRequests/OverlayComplete';
import OverlayRequestBusiness from '../overlayRequests/OverlayRequestBusiness';
import OverlayRequestFarmer from '../overlayRequests/OverlayRequestFarmer';
import OverlayRequestGeneral from '../overlayRequests/OverlayRequestGeneral';
import RequestTypeForm from './RequestTypeForm';

interface Props {}

const OverlayRequestType: React.FC<Props> = ({}) => {
  /**
   * -1 = Closed
   * 0 = Selection
   * 1 = Farmer
   * 2 = Business
   * 3 = General
   * 4 = Complete
   */
  const [overlayState, setOverlayState] = useState(-1);

  return (
    <>
      <div className="request__trigger">
        <DemmiButton text="Get in touch" onClick={() => setOverlayState(0)} />
      </div>

      <RequestTypeForm
        open={overlayState === 0}
        onClose={() => setOverlayState(-1)}
        onClickFarmer={() => setOverlayState(1)}
        onClickBusiness={() => setOverlayState(2)}
        onClickGeneral={() => setOverlayState(3)}
      />

      <OverlayRequestFarmer
        open={overlayState === 1}
        onClose={() => setOverlayState(-1)}
        onFormComplete={() => setOverlayState(4)}
      />
      <OverlayRequestBusiness
        open={overlayState === 2}
        onClose={() => setOverlayState(-1)}
        onFormComplete={() => setOverlayState(4)}
      />
      <OverlayRequestGeneral
        open={overlayState === 3}
        onClose={() => setOverlayState(-1)}
        onFormComplete={() => setOverlayState(4)}
      />
      <OverlayComplete
        open={overlayState === 4}
        onClose={() => setOverlayState(-1)}
      />
    </>
  );
};
export default OverlayRequestType;
