import React, { useRef } from 'react';

import DemmiButton from '@demmi-ui/Button';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiCardArea from '@demmi-ui/Card/CardArea';
import DemmiCardTitleArea from '@demmi-ui/Card/CardTitleArea';
import DemmiModalCore, { DemmiModalCoreRef } from '@demmi-ui/ModalCore';

const CSSBlock = 'register-interest';

interface Props {
  open: boolean;
  onClose: () => void;
}

const OverlayComplete: React.FC<Props> = ({ open, onClose }) => {
  const modalRef = useRef<DemmiModalCoreRef>(null);

  const done = () => {
    if (modalRef.current)
      modalRef.current.gracefulClose(() => {
        onClose();
      });
  };

  return (
    <DemmiModalCore ref={modalRef} isOpen={open} onExited={() => onClose()}>
      <DemmiCard className={CSSBlock} style={{ width: '34rem' }}>
        <DemmiCardTitleArea titleText={<>Thank you</>}></DemmiCardTitleArea>
        <DemmiCardArea isDark>
          <div style={{ padding: ' 2rem', textAlign: 'center' }}>
            We'll get back to you shortly.
          </div>
          <div
            style={{
              paddingBottom: '2rem',
              display: 'flex',
              justifyContent: 'center',
            }}>
            <DemmiButton
              text="We're done here"
              faIcon="fa-regular fa-face-smile-wink"
              onClick={done}
            />
          </div>
        </DemmiCardArea>
      </DemmiCard>
    </DemmiModalCore>
  );
};

export default OverlayComplete;
