import React, { HTMLInputTypeAttribute } from 'react';

interface DemmiTextAreaProps {
  placeholder?: string;
  value?: string;
  name?: string;
  onChange: (val: string) => void;
}

const DemmiTextArea: React.FC<DemmiTextAreaProps> = ({
  placeholder,
  value,
  name,
  onChange,
}) => {
  const CSSBlock = 'demmi-ui-input';

  return (
    <textarea
      className={CSSBlock}
      placeholder={placeholder}
      value={value}
      name={name}
      onChange={e => onChange(e.target.value)}
    />
  );
};

export default DemmiTextArea;
