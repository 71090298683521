import React, { HTMLProps, PropsWithChildren } from 'react';

interface DemmiCardProps extends PropsWithChildren, HTMLProps<HTMLDivElement> {
  isDark?: boolean;
}

const DemmiCardArea: React.FC<DemmiCardProps> = ({
  children,
  isDark,
  ...props
}) => {
  const CSSBlock = 'demmi-ui-card';

  return (
    <div
      {...props}
      className={`${CSSBlock}__area ${
        isDark ? `${CSSBlock}__area--dark` : `${CSSBlock}__area--light`
      } ${props.className ? props.className : ``}`}>
      {children}
    </div>
  );
};

export default DemmiCardArea;
