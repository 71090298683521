import React, { useRef, useState } from 'react';

import DemmiButton from '@demmi-ui/Button';
import DemmiCard from '@demmi-ui/Card/Card';
import DemmiCardArea from '@demmi-ui/Card/CardArea';
import DemmiCardTitleArea from '@demmi-ui/Card/CardTitleArea';
import DemmiModalCore, { DemmiModalCoreRef } from '@demmi-ui/ModalCore';

const CSSBlock = 'request-type';

interface RegisterInterestFormProps {
  open: boolean;
  onClose: () => void;
  onClickFarmer: () => void;
  onClickBusiness: () => void;
  onClickGeneral: () => void;
}

const RequestTypeForm: React.FC<RegisterInterestFormProps> = ({
  open,
  onClose,
  onClickFarmer,
  onClickBusiness,
  onClickGeneral,
}) => {
  const modalRef = useRef<DemmiModalCoreRef>(null);

  return (
    <DemmiModalCore ref={modalRef} isOpen={open} onExited={() => onClose()}>
      <DemmiCard className={CSSBlock}>
        <DemmiCardTitleArea titleText={<>Get in touch</>}></DemmiCardTitleArea>
        <DemmiCardArea isDark>
          <div style={{ padding: '1rem 2rem 0rem' }}>
            <p style={{ margin: '1rem 0' }}>
              Demmi is currently under development, soon to be rolled out
              through its testing market.
            </p>
            <p style={{ margin: '2rem 0' }}>
              Before we start, select an option below:
            </p>
          </div>
          <div
            style={{
              display: 'flex',
              padding: '1rem',
              gap: '1rem',
              flexDirection: 'column',
              justifyContent: 'center',
            }}>
            <DemmiButton
              text="I'm a Farmer"
              onClick={() => onClickFarmer()}
              style={{ width: '100%' }}
            />
            <DemmiButton
              text="Business Interest"
              onClick={() => onClickBusiness()}
              style={{ width: '100%' }}
            />
            <DemmiButton
              text="General Enquiry"
              onClick={() => {
                if (modalRef.current)
                  modalRef.current.gracefulClose(() => {
                    onClickGeneral();
                    // onClose();
                  });
              }}
              style={{ width: '100%' }}
            />
          </div>
        </DemmiCardArea>
      </DemmiCard>
    </DemmiModalCore>
  );
};

export default RequestTypeForm;
